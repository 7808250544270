(function (angular, _) {
    const  MyHippoFormlyDirectives = angular.module('MyHippoFormly.Directives');
    MyHippoFormlyDirectives.directive('mhProducerDetailSubOrgList', mhProducerDetailSubOrg);
    mhProducerDetailSubOrg.$inject = ['toaster', 'APIService'];
    function mhProducerDetailSubOrg (toaster, APIService) {
        return {
            restrict: 'E',
            templateUrl: 'common/formly-directives/policy-update-org/template.html',
            wrapper: [],
            overwriteOk: true,
            controller: function ($scope, $mdDialog, $q) {
                $scope.orgName = '';
                $scope.organizations = [];
                $scope.orgChoice = { selected: null };
                $scope.isEditMode = false;

                const setNewOrgVal = (orgId) => {
                    const matchingOrg = $scope.organizations.find(o => o.id === orgId);
                    $scope.orgName = matchingOrg.name;
                    $scope.orgChoice.selected = matchingOrg.id;
                };

                $scope.cancel = () => {
                    $scope.isEditMode = false;
                    setNewOrgVal(_.get($scope.model, 'organization_id'));
                };

                $scope.saveOrEdit = () => {
                    const currentPolicyOrgId = $scope.model.organization_id;
                    $scope.showTempOption = !$scope.organizations.find(o => o.id === currentPolicyOrgId || o.id === $scope.orgChoice.selected);

                    if ($scope.isEditMode) { // Save
                        const policyId = $scope.model.id;
                        const newOrgId = _.get($scope.orgChoice, 'selected');
                        if (policyId && newOrgId && currentPolicyOrgId !== newOrgId) {
                            APIService.updatePolicyOrganization(policyId, newOrgId).then(policy => {
                                // Remove the temp org after saving to another organization
                                const currOrgIdx = $scope.organizations.findIndex(o => o.id === currentPolicyOrgId);
                                if (currOrgIdx && $scope.organizations[currOrgIdx].temp) {
                                    $scope.organizations.splice(currOrgIdx, 1);
                                }
                                // Set new org name
                                $scope.model.organization_id = policy.organization_id;
                                setNewOrgVal(policy.organization_id);
                                toaster.pop('success', 'Organization saved');
                            }).catch(err => {
                                toaster.pop('error', 'Organization', err || 'Unable to save');
                            }).then(() => {
                                $scope.isEditMode = false;
                            });
                        } else if (currentPolicyOrgId === newOrgId) {
                            $scope.isEditMode = false;
                        }
                    } else {
                        $scope.isEditMode = true;
                    }
                };

                APIService.findAvailableOrganizationsForUser().then((userOrgs) => {
                    const currentPolicyOrgId = $scope.model.organization_id;
                    $scope.organizations = _.cloneDeep(userOrgs);
                    // If policy organization is now outside of the users organization
                    // We still want to find and show the organization on the page
                    if (!$scope.organizations.find(o => o.id === currentPolicyOrgId)) {
                        const { organization_name: name } = $scope.model;
                        $scope.organizations.push({ name, id: currentPolicyOrgId, temp: true });
                    }

                    setNewOrgVal(_.get($scope.model, 'organization_id'));
                });
            }
        };
    }
})(window.angular, window._);
